<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <!-- o v-if desse btn é utilizado para permitir o modificar a senha somente para a senha do usuario corrente -->
      <v-btn
        plain
        dark
        v-bind="attrs"
        v-on="on"
        v-if="currentItem.current_user"
      >
        <v-icon
          small
          class="mr-2"
          color="grey"
        >
          mdi-key
        </v-icon>
      </v-btn>
    </template>
    <v-card 
    >  
      <v-card-title>
        <v-row>
          <v-col>
            Trocar minha senha
          </v-col>
          <v-col class="text-right">
            <v-btn icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        width="600" 
      >
        <v-form ref="form">
          <v-row>

            <v-col cols="8">
              <v-card-title class="white--text">
                {{ item.text }}
              </v-card-title>
              <v-text-field 
                class="mx-4"
                label="Antiga senha"
                v-model="item.old_password"
                required
                :rules="regra_unica"
                :append-icon="tipo ? 'mdi-eye' : 'mdi-eye-off'"
                :type="tipo?'text':'password'"
                @click:append="tipo = !tipo"
              >
              </v-text-field>
              <v-text-field 
                class="mx-4"
                label="Nova senha"
                v-model="item.password"
                required
                :rules="regra"
                :append-icon="tipo ? 'mdi-eye' : 'mdi-eye-off'"
                :type="tipo?'text':'password'"
                @click:append="tipo = !tipo"
              >
              </v-text-field>
              <v-text-field 
                class="mx-4"
                label="Repetir a senha"
                v-model="item.password2"
                required
                :rules="regra"
                :append-icon="tipo ? 'mdi-eye' : 'mdi-eye-off'"
                :type="tipo?'text':'password'"
                @click:append="tipo = !tipo"
              >
              </v-text-field>
              <v-btn class="mx-4" outlined @click="salvar">
                Mudar<v-icon class="ma-2">mdi-content-save-outline</v-icon>
              </v-btn>
            </v-col>
            <!--
            <v-col cols="4" class="text-right">

              <v-avatar
                class="mx-3"
                size="125"
                tile
              >
                <v-img 
                  :src="computed_avatar_preview">
                </v-img>
              </v-avatar>

              <v-file-input counter :loading="loading"
                accept="image/png, image/jpeg, image/bmp"
                show-size
                label="Trocar Avatar"
                @change="onFileSelected"
              >
              </v-file-input>
              <br>
              <v-btn 
                @click.stop="changeAvatar"
                outlined
                v-if="this.avatar"
              >
                salvar
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-col>
            -->
          </v-row>
        </v-form>  

      </v-card-text>
       
    </v-card>
      <!--<v-container>
        <div v-for="(i,v) in config.usuario.papel" :key="v">
          {{i}}
        </div>
      </v-container>-->
        
    <v-snackbar
      v-model="loading"
      height="300"
      width="500"
      :color="cor"
      timeout="6000"
    >
          {{ mensagem }} 
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="fechaLoading"
          text
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name:'TrocaSenha',
  props:{
    currentItem:{
      type:Object,
      default:null
    }
  },
  data:()=>({
    dialog:false,
    loading:false,
    config:{},
    avatar_preview:'',
    url:null,
    regra_unica:[e=>e==undefined || e.length>=4||'Este campo deverá ter a sua antiga senha'],
    tipo:false,
    cfg:JSON.parse(localStorage.getItem('cfgu')),
    
  }),
  computed:{
    ...mapState(['usuarios','avatar','cor','mensagem']),
    computed_avatar_preview(){
      if (this.usuarios[0]!=undefined){
        let usu = this.usuarios[0]
        if (this.avatar != undefined){
          return this.avatar_preview
        }else if ((usu.avatar || '').length > 0){
          return usu.avatar
        }else{
          return 'https://f1.pngfuel.com/png/933/945/137/social-media-icons-background-avatar-user-profile-login-black-circle-silhouette-symbol-png-clip-art.png'
        }
      }else{
        return 'https://f1.pngfuel.com/png/933/945/137/social-media-icons-background-avatar-user-profile-login-black-circle-silhouette-symbol-png-clip-art.png'
      }
    },
    regra(){
      return [
        this.item.password == this.item.password2||'A senha não confere',
        this.item.password == undefined||this.item.password.length>=8||'A senha precisa ter um mínimo de 8 caracteres'
      ]
    },
    item(){
      return this.config == undefined?  {usuario:{}}:this.config 
    }
  },
  methods: {
    
    onFileSelected(event){
      this.$store.dispatch('avatar', event)
      //cria url do arquivo selecionado
      this.avatar_preview = this.avatar != null ? window.URL.createObjectURL(event) : null;
    },
    async salvar(){
      if(this.$refs.form.validate()){
        this.loading = true
        }
    },
    changeAvatar(){
      if(this.$refs.form_change_avatar.validate()){
        let fd = new FormData();
        console.log(fd)
        fd.append('avatar',this.avatar,this.avatar.name)
        this.$store.dispatch('usuarios',{
          method:'put',
          headers : {
            "Content-Type": "multipart/form-data",
            "Content-Disposition": 'attachment; filename*=UTF-8\'\' '+( this.avatar.name ) ,
          },
          data :fd,
          url: this.item.update_url,
          instancia:'contas'
        })
      }
    },
    fechaLoading(){
      this.$store.dispatch('btnLoading',false)
      this.$store.dispatch('cor','')
      this.$store.dispatch('mensagem','')
    },
  },
  mounted(){
    
    //this.$store.dispatch('btnLoading',false)
    //this.config = JSON.parse(localStorage.getItem('cfgu'))
    //this.$store.dispatch('avatar',null)
    
   
  }
}
</script>